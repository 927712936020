/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { set as t, transpose as r } from "../../core/libs/gl-matrix-2/math/mat4.js";
const n = (n, o) => {
    const s = t(n, o, 0, 0, 0, 0, o, 0, 0, 0, 0, o, 0, 0, 0, 0, 1);
    return r(s, s);
  },
  o = (n, o) => {
    const s = t(n, o, 0, 0, .5 - .5 * o, 0, o, 0, .5 - .5 * o, 0, 0, o, .5 - .5 * o, 0, 0, 0, 1);
    return r(s, s);
  },
  s = (n, o) => {
    const s = 1 - o,
      c = t(n, .2126 + .7874 * s, .7152 - .7152 * s, .0722 - .0722 * s, 0, .2126 - .2126 * s, .7152 + .2848 * s, .0722 - .0722 * s, 0, .2126 - .2126 * s, .7152 - .7152 * s, .0722 + .9278 * s, 0, 0, 0, 0, 1);
    return r(c, c);
  },
  c = (n, o) => {
    const s = Math.sin(o * Math.PI / 180),
      c = Math.cos(o * Math.PI / 180),
      e = t(n, .213 + .787 * c - .213 * s, .715 - .715 * c - .715 * s, .072 - .072 * c + .928 * s, 0, .213 - .213 * c + .143 * s, .715 + .285 * c + .14 * s, .072 - .072 * c - .283 * s, 0, .213 - .213 * c - .787 * s, .715 - .715 * c + .715 * s, .072 + .928 * c + .072 * s, 0, 0, 0, 0, 1);
    return r(e, e);
  },
  e = (n, o) => {
    const s = 1 - 2 * o,
      c = t(n, s, 0, 0, o, 0, s, 0, o, 0, 0, s, o, 0, 0, 0, 1);
    return r(c, c);
  },
  a = (n, o) => {
    const s = t(n, .213 + .787 * o, .715 - .715 * o, .072 - .072 * o, 0, .213 - .213 * o, .715 + .285 * o, .072 - .072 * o, 0, .213 - .213 * o, .715 - .715 * o, .072 + .928 * o, 0, 0, 0, 0, 1);
    return r(s, s);
  },
  u = (n, o) => {
    const s = 1 - o,
      c = t(n, .393 + .607 * s, .769 - .769 * s, .189 - .189 * s, 0, .349 - .349 * s, .686 + .314 * s, .168 - .168 * s, 0, .272 - .272 * s, .534 - .534 * s, .131 + .869 * s, 0, 0, 0, 0, 1);
    return r(c, c);
  };
export { n as brightness, o as contrast, s as grayscale, e as invert, c as rotateHue, a as saturate, u as sepia };