/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function e() {
  const e = new Float32Array(16);
  return e[0] = 1, e[5] = 1, e[10] = 1, e[15] = 1, e;
}
function r(e) {
  const r = new Float32Array(16);
  return r[0] = e[0], r[1] = e[1], r[2] = e[2], r[3] = e[3], r[4] = e[4], r[5] = e[5], r[6] = e[6], r[7] = e[7], r[8] = e[8], r[9] = e[9], r[10] = e[10], r[11] = e[11], r[12] = e[12], r[13] = e[13], r[14] = e[14], r[15] = e[15], r;
}
function t(e, r, t, n, o, a, c, u, l, f, i, s, y, w, A, F) {
  const _ = new Float32Array(16);
  return _[0] = e, _[1] = r, _[2] = t, _[3] = n, _[4] = o, _[5] = a, _[6] = c, _[7] = u, _[8] = l, _[9] = f, _[10] = i, _[11] = s, _[12] = y, _[13] = w, _[14] = A, _[15] = F, _;
}
function n(e, r) {
  return new Float32Array(e, r, 16);
}
const o = e(),
  a = Object.freeze(Object.defineProperty({
    __proto__: null,
    IDENTITY: o,
    clone: r,
    create: e,
    createView: n,
    fromValues: t
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { o as IDENTITY, r as clone, e as create, n as createView, t as fromValues, a as m };