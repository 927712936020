/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { createEffectWithInitialValues as n } from "./effects.js";
function t(n, t) {
  const e = n.length > t.length ? n : t;
  return (n.length > t.length ? t : n).every((n, t) => n.type === e[t].type);
}
function e(t, e) {
  const l = t.length > e.length ? t : e,
    r = t.length > e.length ? e : t;
  for (let g = r.length; g < l.length; g++) r.push(n(l[g]));
}
function l(n) {
  const t = n[0];
  return !!t && "type" in t;
}
function r(n, t) {
  return JSON.stringify(n ?? null) === JSON.stringify(t ?? null);
}
export { t as canInterpolateEffects, r as effectEquals, l as isEffectFunctions, e as normalizeEffects };